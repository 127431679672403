// filename: App.js
import React, { createContext, useState, useEffect, useContext, startTransition } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, CssBaseline, useMediaQuery, GlobalStyles } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { auth } from './firebase';
import Explore from './p/Explore';
import Passback from './p/Passback';
import Signup from './p/Signup';
import Login from './p/Login';

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const [themeColorName, setThemeColorName] = useState(() => localStorage.getItem('themeColorName') || 'blue');
  const [fontSize, setFontSize] = useState(() => parseInt(localStorage.getItem('fontSize'), 10) || 14);
  const [backgroundMode, setBackgroundMode] = useState(() => localStorage.getItem('backgroundMode') || 'Light');

  useEffect(() => {
    localStorage.setItem('themeColorName', themeColorName);
  }, [themeColorName]);

  useEffect(() => {
    localStorage.setItem('fontSize', fontSize);
  }, [fontSize]);

  useEffect(() => {
    localStorage.setItem('backgroundMode', backgroundMode);
  }, [backgroundMode]);

  const colorMap = {
    red: '#f44336',
    orange: '#ff9800',
    yellow: '#ffeb3b',
    green: '#4caf50',
    blue: '#2196f3',
    purple: '#9c27b0',
    grey: '#9e9e9e',
    teal: '#009688',
    pink: '#e91e63',
  };

  const themeColor = colorMap[themeColorName] || colorMap['blue'];

  const theme = createTheme({
    typography: { fontSize: fontSize },
    palette: {
      mode: backgroundMode === 'Dark' ? 'dark' : (backgroundMode==='Dim'?'dark':'light'),
      primary: { main: themeColor },
      secondary: { main: themeColor },
      background: {
        default: backgroundMode === 'Light' ? '#ffffff' : (backgroundMode === 'Dim' ? '#696969' : '#000000'),
        paper: backgroundMode === 'Light' ? '#ffffff' : (backgroundMode === 'Dim' ? '#696969' : '#121212'),
      },
      text: { primary: backgroundMode === 'Dark' ? '#ffffff' : '#000000' },
    },
  });

  return (
    <ThemeContext.Provider value={{ themeColorName, setThemeColorName, themeColor, fontSize, setFontSize, backgroundMode, setBackgroundMode, colorMap }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const App = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation();
  const navigate = useNavigate();
  const { themeColor, backgroundMode } = useContext(ThemeContext);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      startTransition(() => {
        setCurrentUser(user);
      });
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/signup') {
      if (currentUser) {
        navigate('/explore', { replace: true });
      }
    } else {
      navigate('/explore', { replace: true });
    }
  }, [currentUser, location.pathname, navigate]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: isMobile ? '60px' : '20px',
          paddingRight: '20px',
          minHeight: '100vh',
          backgroundColor: 'background.default',
          color: 'text.primary',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/explore" replace />} />
          <Route path="/explore/*" element={<Explore />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/passback" element={<Passback />} />
          <Route path="*" element={<Navigate to="/explore" replace />} />
        </Routes>
      </Box>
      <GlobalStyles
        styles={{
          '*::-webkit-scrollbar': { width: '12px' },
          '*::-webkit-scrollbar-track': { background: backgroundMode === 'Dark' ? '#000' : '#fff' },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: themeColor,
            borderRadius: '20px',
            border: '3px solid transparent',
            backgroundClip: 'content-box',
          },
          body: { margin: 0, padding: 0, overflow: 'hidden' },
        }}
      />
    </Box>
  );
};

export default App;