// Signup.jsx
import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../App';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { signUpUser } from '../firebase';
import EmailIcon from '@mui/icons-material/Email';

const Signup = () => {
  const theme = useTheme();
  const { themeColor } = useContext(ThemeContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [step, setStep] = useState('email');
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!credentials.email.trim()) {
      setSnackbar({
        open: true,
        message: 'Enter email address',
        severity: 'warning',
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(credentials.email)) {
      setSnackbar({
        open: true,
        message: 'Enter a valid email address',
        severity: 'warning',
      });
      return;
    }

    setStep('password');
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!credentials.password.trim()) {
      setSnackbar({
        open: true,
        message: 'Please enter your password.',
        severity: 'warning',
      });
      return;
    }

    if (credentials.password.length < 6) {
      setSnackbar({
        open: true,
        message: 'Password must be at least 6 characters long.',
        severity: 'warning',
      });
      return;
    }

    try {
      await signUpUser(credentials.email, credentials.password);
      setSnackbar({
        open: true,
        message: 'Account created successfully! Redirecting to login...',
        severity: 'success',
      });
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'Unable to Sign Up',
        severity: 'error',
      });
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: isMobile ? '10vh' : 13,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.text.primary,
        minHeight: '100vh',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Helmet>
        <title>Sign Up / SPT</title>
        <link rel="icon" href="/favicon-32x32.png" sizes="32x32" />
      </Helmet>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        Sign Up
      </Typography>
      {step === 'email' ? (
        <Box
          component="form"
          onSubmit={handleEmailSubmit}
          sx={{
            mt: 2,
            width: '100%',
            maxWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TextField
            label="Email"
            name="email"
            type="email"
            value={credentials.email}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleEmailSubmit(e);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          {credentials.email.includes('@') && (
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                backgroundColor: themeColor,
                color: '#fff',
                textTransform: 'none',
                fontSize: '1.1rem',
                '&:hover': { backgroundColor: themeColor },
              }}
            >
              Continue
            </Button>
          )}
          <Divider sx={{ width: '100%', my: 2 }} />
          <Button
            variant="outlined"
            fullWidth
            onClick={() => navigate('/login')}
            sx={{
              borderColor: themeColor,
              color: themeColor,
              textTransform: 'none',
              fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: themeColor,
                color: '#fff',
              },
            }}
          >
            Log In
          </Button>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSignup}
          sx={{
            mt: 2,
            width: '100%',
            maxWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TextField
            label="Password"
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            value={credentials.password}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSignup(e);
              }
            }}
            InputProps={{
              startAdornment:
                credentials.password.length >= 1 ? (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                      onClick={togglePasswordVisibility}
                      edge="start"
                      size="small"
                    >
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={!credentials.password.trim() || credentials.password.length < 6}
            sx={{
              backgroundColor: themeColor,
              color: '#fff',
              textTransform: 'none',
              fontSize: '1.1rem',
              '&:hover': { backgroundColor: themeColor },
            }}
          >
            Sign Up
          </Button>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Signup;