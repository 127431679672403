// src/p/Passback.jsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import CheckIcon from '@mui/icons-material/Check';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import { passwordReset, confirmThePasswordReset } from '../firebase';

const Passback = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [passwords, setPasswords] = useState({ newPassword: '', confirmPassword: '' });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [oobCode, setOobCode] = useState(null);
  const [shakeEmailInput, setShakeEmailInput] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search || window.location.hash.substring(1));
    const code = queryParams.get('oobCode');
    setOobCode(code);
  }, [location.search, location.hash]);

  useEffect(() => {
    if (shakeEmailInput) {
      const timer = setTimeout(() => setShakeEmailInput(false), 500);
      return () => clearTimeout(timer);
    }
  }, [shakeEmailInput]);

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handlePasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleSendResetEmail = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      await passwordReset(email);
      setSnackbar({ open: true, message: 'Success!', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Success!', severity: 'success' });
    } finally {
      setIsSaving(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    if (passwords.newPassword !== passwords.confirmPassword) {
      setSnackbar({ open: true, message: 'Passwords did not match.', severity: 'error' });
      setIsSaving(false);
      return;
    }
    if (passwords.newPassword.length < 6) {
      setSnackbar({ open: true, message: 'Password must be at least 6 characters long.', severity: 'error' });
      setIsSaving(false);
      return;
    }
    try {
      if (oobCode) {
        await confirmThePasswordReset(oobCode, passwords.confirmPassword);
        setSnackbar({ open: true, message: 'Your password has been changed successfully.', severity: 'success' });
        setIsSuccess(true);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setSnackbar({ open: true, message: 'Invalid or expired code. Please try again.', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Something went wrong. Please try again later.', severity: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const isSendButtonVisible = email.includes('@');

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: isMobile ? '5vh' : '10vh',
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.text.primary,
        minHeight: '100vh',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Helmet>
        <title>Reset Password / SPT</title>
        <link rel="icon" href="/favicon-32x32.png" sizes="32x32" />
      </Helmet>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: 'center',
          mt: isMobile ? 2 : 0,
          mb: isMobile ? 2 : 4,
        }}
      >
        {oobCode ? 'Set New Password' : 'Reset Password'}
      </Typography>
      {oobCode ? (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            mt: 2,
            width: '100%',
            maxWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TextField
            label="New Password"
            name="newPassword"
            type={isPasswordVisible ? 'text' : 'password'}
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment:
                passwords.newPassword.length >= 1 ? (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                      onClick={togglePasswordVisibility}
                      edge="start"
                      size="small"
                    >
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type={isPasswordVisible ? 'text' : 'password'}
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment:
                passwords.confirmPassword.length >= 1 ? (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                      onClick={togglePasswordVisibility}
                      edge="start"
                      size="small"
                    >
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={
              isSaving ||
              !passwords.newPassword ||
              !passwords.confirmPassword ||
              passwords.newPassword.length < 6 ||
              passwords.newPassword !== passwords.confirmPassword
            }
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
              textTransform: 'none',
              fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {isSuccess ? <CheckIcon /> : 'Save'}
          </Button>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSendResetEmail}
          sx={{
            mt: 2,
            width: '100%',
            maxWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
              sx: {
                animation: shakeEmailInput ? 'shake 0.5s' : 'none',
              },
            }}
          />
          {isSendButtonVisible && (
            <Button
              variant="contained"
              type="submit"
              disabled={isSaving}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                textTransform: 'none',
                fontSize: '1.1rem',
                width: '100%',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              Send
            </Button>
          )}
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: isMobile ? '10vh' : '20vh' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{
            width: 'auto',
            minWidth: '200px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Passback;