// filename: src/p/Explore.jsx
import React, { useState, useEffect, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Slider,
  Grid,
  Paper,
  Button,
  Snackbar,
  Alert,
  Container,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemAvatar,
  BottomNavigation,
  BottomNavigationAction,
  Tabs,
  Tab,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogContent,
  DialogActions,
  Chip,
  Card,
  CardContent,
  CardActions
} from '@mui/material';
import {
  Search as SearchIcon,
  Mic,
  Close,
  Email as EmailIcon,
  Phone as PhoneIcon,
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  PrivacyTipOutlined as PrivacyTipOutlinedIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  AutoAwesomeOutlined as AutoAwesomeOutlinedIcon,
  Person as PersonIcon,
  Send as SendIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  ArrowUpward as ArrowUpwardIcon,
  Clear as ClearIcon,
  Home as HomeIcon,
  Help as HelpIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { db, auth, uploadProfilePicture } from '../firebase';
import { Helmet } from 'react-helmet';
import {
  onAuthStateChanged,
  signOut,
  updateEmail,
  updateProfile,
  getIdToken,
  sendEmailVerification
} from 'firebase/auth';
import { ThemeContext } from '../App';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import { IoArrowBack, IoArrowForward, IoShareOutline } from 'react-icons/io5';
import { BsChat, BsFillHeartFill, BsHeart } from 'react-icons/bs';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';

import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  setDoc,
  where
} from 'firebase/firestore';

const MAX_EVENTS_PER_MONTH = 6;
const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000;

const plans = [
  {
    name: 'Diamond',
    month: '$60',
    annual: '$600',
    perks: ['All', 'Priority', 'Personalized', 'Support', 'Webinars', 'EarlyAccess']
  },
  {
    name: 'Emerald',
    month: '$80',
    annual: '$800',
    perks: ['AllDiamond', 'UltimateEvents', 'VIP', '24/7Support', 'AccountMgr', 'Integrations']
  }
];

const privacySections = [
  { title: 'Privacy', content: 'This is our Privacy Policy...' },
  { title: 'Security', content: 'These are our Security Guidelines...' }
];

const classesData = [
  { id: 'BS161', name: 'Cell and Molecular Biology', discipline: 'Bioscience', credits: 3 },
  { id: 'ENT205', name: 'Pests, Society and Environment', discipline: 'Bioscience', credits: 3 },
  { id: 'IBIO150', name: 'Integrating Biology: From DNA to Populations', discipline: 'Bioscience', credits: 3 },
  { id: 'MMG141', name: 'Introductory Human Genetics', discipline: 'Bioscience', credits: 3 }
];

const disciplines = [
  'Bioscience',
  'Bioscience Lab',
  'Computational Mathematics, Science, & Engineering',
  'Computer Science & Engineering',
  'Math',
  'Statistics'
];

function CardComponent({ event, liked, onLike, onUnlike }) {
  const [hearted, setHearted] = useState(liked);
  const [heartCount, setHeartCount] = useState(event.heartCount || 0);
  const [pinned, setPinned] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [imageIndex, setImageIndex] = useState(0);

  const images = event.images || [];
  const title = event.title || 'No Title';
  const locations = event.locations || [];
  const dateTime = event.dateTime;
  const hasMultipleImages = images.length > 1;

  const handleDotClick = (index) => { if (index !== imageIndex) setImageIndex(index); };
  const handlePrevImage = (e) => { e.stopPropagation(); setImageIndex(prev => prev > 0 ? prev - 1 : prev); };
  const handleNextImage = (e) => { e.stopPropagation(); setImageIndex(prev => prev < images.length - 1 ? prev + 1 : prev); };

  const toggleHeart = (e) => {
    e.stopPropagation();
    if (!hearted) {
      setHearted(true);
      setHeartCount(c => c + 1);
      onLike && onLike(event.id);
    } else {
      setHearted(false);
      setHeartCount(c => Math.max(c - 1, 0));
      onUnlike && onUnlike(event.id);
    }
  };

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer',
          width: { xs: '100%', sm: '240px' },
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '10px',
          transition: 'transform 0.3s, box-shadow 0.3s',
          margin: '0 auto',
          backgroundColor: '#1a1a1a',
          '&:hover': { transform: 'scale(1.03)', boxShadow: 6 }
        }}
      >
        <Box sx={{
          position: 'relative',
          height: '180px',
          overflow: 'hidden',
          '&:hover .arrowButton': { opacity: 1 },
          '&:hover .bottomIcons': { opacity: 1, transform: 'translateY(0)' }
        }}>
          <Box
            component="img"
            height="180"
            src={images[imageIndex] || 'https://via.placeholder.com/240x180'}
            alt={title}
            loading="lazy"
            sx={{ objectFit: 'cover', width: '100%' }}
          />
          {hasMultipleImages && imageIndex > 0 && (
            <IconButton
              className="arrowButton"
              onClick={handlePrevImage}
              aria-label="previous image"
              sx={{
                opacity: 0,
                transition: 'opacity 0.3s',
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255,255,255,0.7)',
                color: '#000',
                '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' }, zIndex: 2
              }}
            >
              <IoArrowBack size={20} />
            </IconButton>
          )}
          {hasMultipleImages && imageIndex < images.length - 1 && (
            <IconButton
              className="arrowButton"
              onClick={handleNextImage}
              aria-label="next image"
              sx={{
                opacity: 0,
                transition: 'opacity 0.3s',
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255,255,255,0.7)',
                color: '#000',
                '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' }, zIndex: 2
              }}
            >
              <IoArrowForward size={20} />
            </IconButton>
          )}
          {hasMultipleImages && (
            <Box sx={{
              position: 'absolute',
              top: '8px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: '6px',
              zIndex: 2
            }}>
              {images.map((_, idx) => (
                <Box
                  key={idx}
                  onClick={(e) => { e.stopPropagation(); handleDotClick(idx); }}
                  sx={{
                    width: '8px', height: '8px', borderRadius: '50%',
                    backgroundColor: idx === imageIndex ? 'primary.main' : 'rgba(255,255,255,0.7)',
                    cursor: 'pointer', transition: 'background-color 0.3s'
                  }}
                />
              ))}
            </Box>
          )}
          <Box sx={{ position: 'absolute', top: '8px', right: '8px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={toggleHeart}
                aria-label="like"
                sx={{
                  color: hearted ? 'red' : '#fff',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
                  mb: 1
                }}
                disableRipple
              >
                {hearted ? <BsFillHeartFill size={20} /> : <BsHeart size={20} />}
              </IconButton>
              {heartCount > 0 && (
                <Box sx={{
                  position: 'absolute', top: '28px', right: '8px', backgroundColor: 'red', color: '#fff',
                  borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center',
                  alignItems: 'center', fontSize: '12px', zIndex: 3
                }}>
                  {heartCount}
                </Box>
              )}
            </Box>
          </Box>
          <Box className="bottomIcons" sx={{
            position: 'absolute',
            bottom: '8px',
            left: '50%',
            transform: 'translateX(-50%) translateY(20px)',
            display: 'flex',
            gap: '8px',
            transition: 'opacity 0.3s, transform 0.3s',
            opacity: 0,
            zIndex: 3
          }}>
            <IconButton
              onClick={(e) => { e.stopPropagation(); setOpenCommentDialog(true); }}
              aria-label="comment"
              sx={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: '#fff',
                '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                borderRadius: '50%'
              }}
            >
              <BsChat size={20} />
            </IconButton>
            <IconButton onClick={(e) => { e.stopPropagation(); setPinned(p => !p); }} aria-label="pin"
              sx={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: '#fff',
                '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                borderRadius: '50%'
              }}
            >
              {pinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
            </IconButton>
            <IconButton
              onClick={(e) => { e.stopPropagation(); navigator.clipboard.writeText(window.location.href); }}
              aria-label="share"
              sx={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: '#fff',
                '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                borderRadius: '50%'
              }}
            >
              <IoShareOutline size={20} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', p: 2, flexGrow: 1, color: '#fff' }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            title={title}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', mb: 1, color: '#ccc' }}
            title={locations.join(', ') || 'No Location'}
          >
            {locations.join(', ') || 'Location Not Specified'}
          </Typography>
          <Typography variant="body2" sx={{ color: '#ccc' }}>
            {dateTime?.toDate
              ? `${new Date(dateTime.toDate()).toLocaleDateString()} | ${new Date(dateTime.toDate()).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
              : 'Date/Time N/A'}
          </Typography>
        </Box>
      </Paper>

      <Dialog
        open={openCommentDialog}
        onClose={() => setOpenCommentDialog(false)}
        fullWidth
        maxWidth="sm"
        sx={{ '& .MuiDialog-paper': { backgroundColor: '#222', color: '#fff' } }}
      >
        <DialogActions sx={{ justifyContent: 'flex-end', padding: '8px' }}>
          <IconButton onClick={() => setOpenCommentDialog(false)} sx={{ color: '#fff' }}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ pt: 0 }}>
          <Typography variant="subtitle1" gutterBottom>
            Comment on "{title}"
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            variant="standard"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            autoComplete="off"
            InputProps={{ style: { color: '#fff' } }}
            sx={{ '& .MuiInput-underline:before': { borderBottom: '1px solid #fff' }, '& .MuiInput-underline:after': { borderBottom: '2px solid #fff' } }}
          />
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={() => {
              if (commentText.trim() !== '') {
              }
              setOpenCommentDialog(false);
            }}
            sx={{ color: '#fff' }}
          >
            <CheckCircleIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

function SortableImage({ id, src, onRemove, isMobile }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab'
  };
  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{
        position: 'relative',
        width: isMobile ? 100 : 100,
        height: isMobile ? 100 : 100,
        borderRadius: '50%',
        overflow: 'hidden',
        boxShadow: isDragging ? 3 : 1,
        backgroundColor: '#333',
        border: `2px dashed #444`,
        display:'flex',alignItems:'center',justifyContent:'center'
      }}
    >
      {src ? (
        <Box
          component="img"
          src={src}
          alt="Uploaded Preview"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      ) : (
        <Typography variant="body2" sx={{color:'#fff'}}>No Image</Typography>
      )}
      <IconButton
        size="small"
        onClick={onRemove}
        sx={{
          position: 'absolute',
          top: 4,
          right: 4,
          backgroundColor: 'rgba(50,50,50,0.6)',
          color: '#fff',
          '&:hover': { backgroundColor: 'rgba(50,50,50,0.8)' }
        }}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}

const Explore = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    themeColorName,
    setThemeColorName,
    backgroundMode,
    setBackgroundMode,
    fontSize,
    setFontSize,
    colorMap
  } = useContext(ThemeContext);

  const [bottomNavValue, setBottomNavValue] = useState('/explore');
  const [events, setEvents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(40);
  const [searchQuery, setSearchQuery] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newUsername, setNewUsername] = useState('');
  const [changesMade, setChangesMade] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [phoneNotifications, setPhoneNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [lastUsernameChange, setLastUsernameChange] = useState(null);
  const [canChangeUsername, setCanChangeUsername] = useState(true);
  const [verifyEmailClicked, setVerifyEmailClicked] = useState(false);
  const [verifyPhoneClicked, setVerifyPhoneClicked] = useState(false);
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [pricingType, setPricingType] = useState('annual');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [chatInput, setChatInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [likes, setLikes] = useState({});
  const [likeCount, setLikeCount] = useState(0);
  const [privacyTab, setPrivacyTab] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [eduOpen, setEduOpen] = useState(false);
  const [eduTab, setEduTab] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [filteredClasses, setFilteredClasses] = useState(classesData);
  const [selectedClassesEDU, setSelectedClassesEDU] = useState([]);
  const [selectedClassDetail, setSelectedClassDetail] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const [title, setTitle] = useState('');
  const [locationsArr, setLocationsArr] = useState([]);
  const [inputLocation, setInputLocation] = useState('');
  const [dateC, setDateC] = useState('');
  const [timeC, setTimeC] = useState('');
  const [imagesArr, setImagesArr] = useState([]);
  const [errorC, setErrorC] = useState(null);
  const [successC, setSuccessC] = useState(null);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const isFormComplete = title.trim() && locationsArr.length > 0 && dateC.trim() && timeC.trim() && imagesArr.length > 0;

  const allLocations = ['Location A', 'Location B', 'Location C', 'Location D'];
  const customFilterOptions = createFilterOptions({ matchFrom: 'start', stringify: (option) => option });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPhoneNotifications(userData.phoneNotifications || false);
          setEmailNotifications(userData.emailNotifications || false);
          setNewEmail(userData.email || '');
          setNewPhone(userData.phone || '');
          if (!user.displayName) {
            await updateProfile(user, { displayName: 'Spartan' });
          }
          setNewUsername(user.displayName || 'Spartan');
          setLikeCount(userData.likeCount || 0);
          if (userData.lastUsernameChange) {
            const lastChange = userData.lastUsernameChange.toDate();
            setLastUsernameChange(lastChange);
            const now = Date.now();
            setCanChangeUsername(now - lastChange.getTime() > ONE_WEEK_MS);
          } else {
            setCanChangeUsername(true);
          }
        } else {
          await setDoc(userDocRef, {
            email: user.email || '',
            displayName: user.displayName || 'Spartan',
            createdAt: Timestamp.now(),
            likeCount: 0
          });
          setNewUsername(user.displayName || 'Spartan');
          setCanChangeUsername(true);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsSnapshot = await getDocs(query(collection(db, 'events'), orderBy('createdAt', 'desc')));
        const allEvents = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setEvents(allEvents);
      } catch {}
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    if (searchInput.trim() === '') {
      setFilteredClasses(classesData);
    } else {
      const filtered = classesData.filter((cls) =>
        cls.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        cls.id.toLowerCase().includes(searchInput.toLowerCase())
      );
      setFilteredClasses(filtered);
    }
  }, [searchInput]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const filterEvents = (eventsList) =>
    eventsList.filter((event) => {
      const title = event.title || '';
      const locations = event.locations || [];
      const queryLower = searchQuery.toLowerCase();
      return (
        title.toLowerCase().includes(queryLower) ||
        locations.some((loc) => loc.toLowerCase().includes(queryLower))
      );
    });

  const filteredEvents = filterEvents(events).slice(0, visibleCount);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleLogout = () => { signOut(auth).catch(() => {}); window.location.href="/login"; };

  const handleEditClick = () => {
    setNewUsername(currentUser?.displayName || 'Spartan');
    setNewEmail(currentUser?.email || '');
    setNewPhone('');
    setOpenSettingsDialog(true);
    handleMenuClose();
  };

  const handleDialogClose = async () => {
    if (changesMade) {
      await handleSave();
    }
    setOpenSettingsDialog(false);
    setSelectedFile(null);
    setChangesMade(false);
    setVerifyEmailClicked(false);
    setVerifyPhoneClicked(false);
  };

  const handleUsernameSave = async () => {
    let updated = false;
    if (!newUsername.trim()) {
      setFeedbackMessage('Username cannot be empty.');
      setOpenSnackbar(true);
      return updated;
    }
    if (!canChangeUsername) {
      setFeedbackMessage('You can only change your username once a week.');
      setOpenSnackbar(true);
      return updated;
    }
    if (currentUser && newUsername !== currentUser.displayName) {
      const usersRef = collection(db, 'users');
      const qUser = query(usersRef, where('displayName', '==', newUsername));
      const querySnapshot = await getDocs(qUser);
      if (!querySnapshot.empty) {
        setFeedbackMessage('Username already taken.');
        setOpenSnackbar(true);
        return updated;
      }
      await updateProfile(currentUser, { displayName: newUsername });
      await updateDoc(doc(db, 'users', currentUser.uid), {
        displayName: newUsername,
        lastUsernameChange: Timestamp.now()
      });
      updated = true;
    }
    return updated;
  };

  const handleEmailSave = async () => {
    let updated = false;
    if (!currentUser) return updated;
    if (newEmail.trim() === '') {
      if (currentUser.email) {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          email: '',
          lastEmailChange: Timestamp.now()
        });
        updated = true;
      }
    } else {
      if (currentUser.email !== newEmail) {
        try {
          await updateEmail(currentUser, newEmail);
          await updateDoc(doc(db, 'users', currentUser.uid), { email: newEmail });
          updated = true;
        } catch {
          setFeedbackMessage('Failed to update email.');
          setOpenSnackbar(true);
        }
      }
    }
    return updated;
  };

  const handleAvatarChange = async () => {
    if (!selectedFile || !currentUser) return false;
    try {
      const photoURL = await uploadProfilePicture(currentUser.uid, selectedFile);
      await updateProfile(currentUser, { photoURL });
      await updateDoc(doc(db, 'users', currentUser.uid), { photoURL });
      setChangesMade(true);
      return true;
    } catch {
      setFeedbackMessage('Failed to upload avatar.');
      setOpenSnackbar(true);
      return false;
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setChangesMade(true);
    }
  };

  const handleSave = async () => {
    const usernameUpdated = await handleUsernameSave();
    const emailUpdated = await handleEmailSave();
    const avatarUpdated = await handleAvatarChange();
    if (currentUser) {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        phoneNotifications,
        emailNotifications
      });
    }
    if (usernameUpdated || emailUpdated || avatarUpdated || changesMade) {
      setFeedbackMessage('Profile updated successfully.');
      setOpenSnackbar(true);
    }
  };

  const handleOpenPrivacy = () => {
    setOpenPrivacyDialog(true);
    setOpenHelpDialog(false);
  };
  const handleClosePrivacy = () => setOpenPrivacyDialog(false);
  const handleOpenHelp = () => {
    setOpenHelpDialog(true);
    setOpenPrivacyDialog(false);
  };
  const handleCloseHelp = () => setOpenHelpDialog(false);
  const handleOpenUpgrade = () => { setOpenUpgradeDialog(true); handleMenuClose(); };
  const handleCloseUpgrade = () => setOpenUpgradeDialog(false);
  const handleOpenEdu = () => { setEduOpen(true); handleMenuClose(); };

  const handleThemeChange = (color) => setThemeColorName(color);
  const handleBackgroundChange = (mode) => setBackgroundMode(mode);
  const handleFontSizeChange = (event, value) => setFontSize(value);

  const handleSubscribe = async (plan) => {
    if (!plan || !currentUser) return;
    try {
      const token = await getIdToken(currentUser);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/subscribe/subscribe`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ plan: plan.name })
        }
      );
      const data = await response.json();
      if (response.ok) {
        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId: data.sessionId });
      } else {
        alert(data.msg || 'Failed to create subscription. Please try again.');
      }
    } catch {
      alert('An unexpected error occurred. Please try again.');
    }
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();
    if (!chatInput.trim()) return;
    setChatLoading(true);
    setTimeout(() => {
      const userMessage = { role: 'user', content: chatInput };
      const botMessage = { role: 'assistant', content: `You asked: ${chatInput}. Here is a helpful answer.` };
      setChatHistory([...chatHistory, userMessage, botMessage]);
      setChatInput('');
      setChatLoading(false);
    }, 1000);
  };

  const handleVoiceSearch = () => {
    if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
      alert('Speech Recognition not supported.');
      return;
    }
    if (recognition) {
      recognition.stop();
      setRecognition(null);
    } else {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const newRecognition = new SpeechRecognition();
      newRecognition.continuous = false;
      newRecognition.interimResults = false;
      newRecognition.lang = 'en-US';
      newRecognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setSearchQuery(transcript);
        setRecognition(null);
      };
      newRecognition.onerror = () => setRecognition(null);
      newRecognition.start();
      setRecognition(newRecognition);
    }
  };

  const handleLike = (eventId) => {
    if (!currentUser) {
      setFeedbackMessage('Login to like.');
      setOpenSnackbar(true);
      return;
    }
    if (likeCount >= 10) {
      setFeedbackMessage('You have reached the monthly like limit.');
      setOpenSnackbar(true);
      return;
    }
    if (likes[eventId]) {
      setFeedbackMessage('You have already liked this event.');
      setOpenSnackbar(true);
      return;
    }
    setLikes({ ...likes, [eventId]: true });
    setLikeCount((prev) => prev + 1);
  };

  const handleUnlike = (eventId) => {
    if (!currentUser) {
      setFeedbackMessage('Login to unlike.');
      setOpenSnackbar(true);
      return;
    }
    const newLikes = { ...likes };
    delete newLikes[eventId];
    setLikes(newLikes);
    setLikeCount((prev) => prev - 1);
  };

  const handlePrivacyTabChange = (event, newValue) => setPrivacyTab(newValue);
  const handlePricingTypeChange = (event, newPricingType) => { if (newPricingType !== null) setPricingType(newPricingType); };

  const openSearchPopup = () => setSearchOpen(true);
  const closeSearchPopup = () => setSearchOpen(false);

  const handleLoadMore = () => setVisibleCount((prev) => prev + 40);

  const handleVerifyEmail = async () => {
    if (currentUser && !currentUser.emailVerified) {
      await sendEmailVerification(currentUser);
      setFeedbackMessage('Verification email sent!');
      setOpenSnackbar(true);
    }
  };

  const handleVoiceSearchEDU = () => {
    if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
      alert('Speech Recognition not supported in this browser.');
      return;
    }
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognitionEDU = new SpeechRecognition();
    recognitionEDU.continuous = false;
    recognitionEDU.interimResults = false;
    recognitionEDU.lang = 'en-US';
    recognitionEDU.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchInput(transcript);
    };
    recognitionEDU.onerror = () => { recognitionEDU.stop(); };
    recognitionEDU.start();
  };

  const checkEventLimit = async () => {
    return 0;
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();
    if (!isFormComplete) {
      setErrorC('Please fill in all fields, select at least one location, and upload at least one image.');
      return;
    }
    setLoadingC(true);
    setErrorC(null);
    setSuccessC(null);
    try {
      const user = auth.currentUser;
      if (!user) {
        setErrorC('Log in!');
        setLoadingC(false);
        return;
      }
      const eventCount = await checkEventLimit();
      if (eventCount >= MAX_EVENTS_PER_MONTH) {
        setErrorC(`You have reached the limit of ${MAX_EVENTS_PER_MONTH} events for this month.`);
        setLoadingC(false);
        return;
      }
      const imageUrls = [];
      for (const img of imagesArr) {
        const { getStorage, ref, uploadBytes, getDownloadURL } = await import('firebase/storage');
        const storage = getStorage();
        const storageRefPath = `eventImages/${user.uid}/${Date.now()}_${img.file.name}`;
        const storageRefFinal = ref(storage, storageRefPath);
        const snapshot = await uploadBytes(storageRefFinal, img.file);
        const url = await getDownloadURL(snapshot.ref);
        imageUrls.push(url);
      }

      const dateTimeString = `${dateC}T${timeC}:00`;
      const eventDateTime = new Date(dateTimeString);
      const timestamp = Timestamp.fromDate(eventDateTime);
      const eventData = {
        title,
        locations: locationsArr,
        dateTime: timestamp,
        images: imageUrls,
        creatorId: user.uid,
        creatorEmail: user.email,
        createdAt: Timestamp.now()
      };
      await setDoc(doc(collection(db, 'events')), eventData);
      setSuccessC('Event created successfully!');
      setTitle('');
      setLocationsArr([]);
      setDateC('');
      setTimeC('');
      setImagesArr([]);
      setOpenCreateDialog(false);
    } catch (err) {
      setErrorC(`An error occurred while creating the event: ${err.message}`);
    } finally {
      setLoadingC(false);
    }
  };

  useEffect(() => {
    if (errorC) {
      const timer = setTimeout(() => setErrorC(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorC]);

  useEffect(() => {
    if (successC) {
      const timer = setTimeout(() => setSuccessC(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [successC]);

  useEffect(() => {
    return () => {
      imagesArr.forEach((img) => URL.revokeObjectURL(img.preview));
    };
  }, [imagesArr]);

  useEffect(() => {
    if (inputLocation.length > 0 && allLocations.filter((loc) => loc.toLowerCase().startsWith(inputLocation.toLowerCase())).length > 0) {
      setOpenAutocomplete(true);
    } else {
      setOpenAutocomplete(false);
    }
  }, [inputLocation, allLocations]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: '#000' }}>
      <Helmet>
        <title>SptGPT</title>
      </Helmet>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="info" sx={{ width: '100%' }}>
          {feedbackMessage}
        </Alert>
      </Snackbar>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: '#000',
          color: '#fff',
          display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',
          p:1
        }}
      >
        <Toolbar sx={{minHeight:'auto',p:0}}>
          <IconButton onClick={openSearchPopup} sx={{color:'#fff'}}>
            <SearchIcon />
          </IconButton>
          <IconButton onClick={() => setOpenCreateDialog(true)} sx={{color:'#fff',ml:1}}>
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
          {!isMobile && (
            <IconButton onClick={handleMenuOpen} sx={{color:'#fff',ml:1}}>
              <Avatar src={currentUser?.photoURL || ''} alt="Profile" sx={{width:32,height:32}}/>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Dialog
        open={searchOpen}
        onClose={closeSearchPopup}
        fullScreen
        sx={{ '& .MuiDialog-paper': { backgroundColor: '#000', color: '#fff' } }}
      >
        <DialogActions sx={{ justifyContent: 'flex-end', p:1 }}>
          <IconButton onClick={closeSearchPopup} sx={{color:'#fff'}}>
            <Close/>
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',pt:4 }}>
          <Box sx={{ width:'300px' }}>
            <TextField
              fullWidth
              placeholder=""
              value={searchQuery}
              onChange={(e)=>{setSearchQuery(e.target.value)}}
              variant="outlined"
              InputProps={{
                startAdornment:(
                  <InputAdornment position="start">
                    <SearchIcon sx={{color:'#fff'}}/>
                  </InputAdornment>
                ),
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton onClick={handleVoiceSearch}>
                      <Mic sx={{color:'#fff'}}/>
                    </IconButton>
                  </InputAdornment>
                ),
                style:{color:'#fff',backgroundColor:'#333',borderRadius:'30px'}
              }}
              onKeyPress={(e)=>{ if(e.key==='Enter'){closeSearchPopup();} }}
            />
          </Box>
          <Box sx={{mt:2}}>
            <Typography variant="h5" sx={{opacity:0.7}}>Type to filter events below</Typography>
          </Box>
          <Box sx={{flexGrow:1,width:'100%',overflowY:'auto',mt:4,
            '&::-webkit-scrollbar': { width:'8px' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.primary.main, borderRadius:'4px' }
          }}>
            <Container maxWidth="lg">
              <Grid container spacing={2} justifyContent="center" sx={{ margin:'0 auto' }}>
                {filterEvents(events).map((event) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={event.id} sx={{ display:'flex', justifyContent:'center' }}>
                    <CardComponent
                      event={event}
                      showIcons={true}
                      onLike={handleLike}
                      onUnlike={handleUnlike}
                      liked={!!likes[event.id]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </DialogContent>
      </Dialog>

      {!isMobile && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ '& .MuiPaper-root': { backgroundColor:'#1a1a1a', color:'#fff', minWidth:'150px' } }}
        >
          <MenuItem onClick={handleOpenEdu}><PersonIcon sx={{mr:1}}/>Edu</MenuItem>
          <MenuItem onClick={handleOpenHelp}><HelpOutlineOutlinedIcon sx={{mr:1}}/>Help</MenuItem>
          <MenuItem onClick={handleEditClick}><PrivacyTipOutlinedIcon sx={{mr:1}}/>Settings</MenuItem>
          <Divider sx={{backgroundColor:'#333'}}/>
          <MenuItem onClick={handleOpenUpgrade}><AutoAwesomeOutlinedIcon sx={{mr:1}}/>Upgrade</MenuItem>
          <Divider sx={{backgroundColor:'#333'}}/>
          {!currentUser ? (
            <MenuItem onClick={()=>{handleLogout()}}><LogoutOutlinedIcon sx={{mr:1}}/>Login</MenuItem>
          ) : (
            <MenuItem onClick={()=>{handleLogout()}}><LogoutOutlinedIcon sx={{mr:1}}/>Logout</MenuItem>
          )}
        </Menu>
      )}

      <Box
        sx={{
          flexGrow:1,
          overflowY:'auto',
          px:2,
          pt:2,
          backgroundColor:'#000',
          '&::-webkit-scrollbar': {
            width:'8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius:'4px'
          }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="center" sx={{ margin:'0 auto' }}>
            {filteredEvents.map((event) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={event.id} sx={{ display:'flex', justifyContent:'center' }}>
                <CardComponent
                  event={event}
                  showIcons={true}
                  onLike={handleLike}
                  onUnlike={handleUnlike}
                  liked={!!likes[event.id]}
                />
              </Grid>
            ))}
          </Grid>
          {visibleCount < filterEvents(events).length && (
            <Box sx={{ textAlign:'center', mt:2, mb:2 }}>
              <IconButton
                onClick={handleLoadMore}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color:'white',
                  '&:hover': { backgroundColor: theme.palette.primary.light },
                  borderRadius:'50px'
                }}
              >
                <ArrowUpwardIcon sx={{transform:'rotate(180deg)'}}/>
              </IconButton>
            </Box>
          )}
        </Container>
      </Box>

      {showScrollTop && (
        <Box sx={{ position:'fixed', bottom:16, right:16, zIndex:9999 }}>
          <IconButton
            onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
              '&:hover': { backgroundColor: theme.palette.primary.light },
              borderRadius:'50%'
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      )}

      {isMobile && (
        <BottomNavigation
          value={bottomNavValue}
          onChange={(event, newValue) => { setBottomNavValue(newValue); }}
          showLabels={false}
          sx={{ position:'fixed', bottom:0, left:0, right:0, backgroundColor:'#1a1a1a',color:'#fff' }}
        >
          <BottomNavigationAction value="/home" icon={<HomeIcon sx={{color:'#fff'}}/>} onClick={()=>window.location.href='/'} />
          <BottomNavigationAction value="/explore" icon={<AutoAwesomeOutlinedIcon sx={{color:'#fff'}}/>} onClick={()=>window.location.href='/explore'}/>
          <BottomNavigationAction value="/edu" icon={<PersonIcon sx={{color:'#fff'}}/>} onClick={()=>setEduOpen(true)}/>
        </BottomNavigation>
      )}

      <Dialog
        open={openSettingsDialog}
        onClose={handleDialogClose}
        fullScreen
        sx={{ '& .MuiDialog-paper': { overflow:'hidden',backgroundColor:'#333',color:'#fff' } }}
      >
        <DialogActions sx={{ justifyContent:'flex-end', padding:'8px' }}>
          <IconButton onClick={handleDialogClose} sx={{color:'#fff'}}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent
          sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'flex-start',
            height:'100%',
            overflow:'hidden',
            pt:2
          }}
        >
          <Box onClick={() => document.getElementById('avatar-input').click()} sx={{cursor:'pointer', mb:2}}>
            <Avatar
              src={
                selectedFile
                  ? URL.createObjectURL(selectedFile)
                  : currentUser?.photoURL || ''
              }
              alt="Profile Picture"
              sx={{
                width: 100,
                height: 100,
                border: `2px solid ${colorMap[themeColorName] || colorMap['blue']}`,
                marginBottom:'16px'
              }}
            />
            <input
              id="avatar-input"
              type="file"
              accept="image/*"
              hidden
              onChange={handleFileSelect}
            />
          </Box>
          <Box sx={{width:'100%',maxWidth:'300px',mx:'auto',textAlign:'left',color:'#fff'}}>
            <Box sx={{display:'flex',alignItems:'center',mb:2}}>
              <EmailIcon sx={{mr:1}}/>
              <TextField
                variant="standard"
                value={newEmail}
                onChange={(e)=>{setNewEmail(e.target.value);setChangesMade(true);}}
                placeholder="Email"
                fullWidth
                InputProps={{
                  style:{color:'#fff'},
                  disableUnderline:true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={emailNotifications ? handleVerifyEmail : ()=>setEmailNotifications(true)} sx={{color:'#fff'}}>
                        {emailNotifications ? (<NotificationsActiveIcon color="primary" />):(<NotificationsOffIcon sx={{color:'#fff'}}/>)}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Divider sx={{backgroundColor:'#555',my:1}}/>
            <Box sx={{display:'flex',alignItems:'center',mb:2}}>
              <PhoneIcon sx={{mr:1}}/>
              <TextField
                variant="standard"
                value={newPhone}
                onChange={(e)=>{setNewPhone(e.target.value);setChangesMade(true);}}
                placeholder="Phone"
                fullWidth
                InputProps={{
                  style:{color:'#fff'},
                  disableUnderline:true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={phoneNotifications ? ()=>setVerifyPhoneClicked(true) : ()=>setPhoneNotifications(true)} sx={{color:'#fff'}}>
                        {phoneNotifications ? (<NotificationsActiveIcon color="primary" />):(<NotificationsOffIcon sx={{color:'#fff'}}/>)}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Divider sx={{backgroundColor:'#555',my:1}}/>
            <Box sx={{display:'flex',alignItems:'center',mb:2}}>
              <PersonIcon sx={{mr:1}}/>
              <TextField
                variant="standard"
                value={newUsername}
                onChange={(e)=>{setNewUsername(e.target.value);setChangesMade(true);}}
                placeholder="Username"
                fullWidth
                InputProps={{
                  style:{color:'#fff'},
                  disableUnderline:true,
                  readOnly:!canChangeUsername
                }}
              />
            </Box>
          </Box>

          <Typography variant="h6" sx={{ mt:2, mb:1, textAlign:'center' }}>
            Background
          </Typography>
          <Box sx={{ display:'flex', justifyContent:'center' }}>
            {['Light', 'Dim', 'Dark'].map((mode) => (
              <IconButton
                key={mode}
                onClick={() => handleBackgroundChange(mode)}
                sx={{
                  backgroundColor:
                    mode === 'Light' ? '#fff' : (mode === 'Dim' ? '#1c2a4f' : '#000'),
                  margin:'4px',
                  border: backgroundMode === mode ? `2px solid ${theme.palette.primary.main}`:'2px solid transparent',
                  width:36,
                  height:36
                }}
              />
            ))}
          </Box>

          <Typography variant="h6" sx={{ mt:2, mb:1,textAlign:'center' }}>
            Color
          </Typography>
          <Box sx={{ display:'flex', flexWrap:'wrap', justifyContent:'center' }}>
            {Object.keys(colorMap).map((color) => (
              <IconButton
                key={color}
                onClick={() => handleThemeChange(color)}
                sx={{
                  backgroundColor: colorMap[color],
                  margin:'4px',
                  border: themeColorName===color?`2px solid ${theme.palette.primary.main}`:'2px solid transparent',
                  width:36,
                  height:36
                }}
              />
            ))}
          </Box>

          <Typography variant="h6" sx={{mt:2,mb:1,textAlign:'center'}}>
            Size
          </Typography>
          <Slider
            value={fontSize}
            min={12}
            max={24}
            step={1}
            onChange={handleFontSizeChange}
            sx={{ width:'80%', maxWidth:'300px',color:'#fff' }}
          />

          <IconButton onClick={handleOpenPrivacy} sx={{position:'absolute',bottom:16,right:16,backgroundColor:'#444','&:hover':{backgroundColor:'#666'}}}>
            <HelpIcon sx={{color:'#fff'}}/>
          </IconButton>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openHelpDialog}
        onClose={handleCloseHelp}
        fullScreen
        sx={{ '& .MuiDialog-paper': { overflow:'hidden',backgroundColor:'#333',color:'#fff'} }}
      >
        <DialogActions sx={{ justifyContent:'flex-end', padding:'8px' }}>
          <IconButton onClick={handleCloseHelp} sx={{color:'#fff'}}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ display:'flex', flexDirection:'column', height:'100%', overflow:'hidden' }}>
          <Typography variant="h6" align="center" gutterBottom>
            Help Assistant
          </Typography>
          <Box
            sx={{
              flexGrow:1,
              overflowY:'auto',
              p:2,
              mt:2,
              backgroundColor:'transparent',
              boxShadow:'none',
              '&::-webkit-scrollbar': { width:'8px' },
              '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.primary.main, borderRadius:'4px' }
            }}
          >
            <List sx={{ width:'100%',color:'#fff' }}>
              {chatHistory.map((msg, index) => (
                <ListItem key={index} alignItems="flex-start" sx={{ mb:2 }}>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'#555'}}>
                      {msg.role === 'user' ? (currentUser?.displayName || 'Y')[0] : 'A'}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={msg.role === 'user' ? currentUser?.displayName || 'You' : 'Assistant'}
                    secondary={
                      <Typography component="span" variant="body2" color="#ccc">
                        {msg.content}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
              {chatLoading && (
                <ListItem>
                  <ListItemText
                    primary="Assistant"
                    secondary={
                      <Typography component="span" variant="body2" color="#ccc">
                        <CircularProgress size={20} />
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Box>
          <Box
            component="form"
            onSubmit={handleChatSubmit}
            sx={{ display:'flex', flexDirection:'column', alignItems:'center', p:1, borderTop:`1px solid ${theme.palette.divider}` }}
          >
            <TextField
              placeholder="Type your question..."
              variant="outlined"
              fullWidth
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              sx={{'& .MuiOutlinedInput-root':{borderRadius:'25px',backgroundColor:'#444',color:'#fff'},mb:1}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit" color="primary" disabled={chatLoading}>
                      <SendIcon sx={{color:'#fff'}}/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e)=>{ if(e.key==='Enter'){handleChatSubmit(e);} }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openPrivacyDialog}
        onClose={handleClosePrivacy}
        fullScreen
        sx={{ '& .MuiDialog-paper': { overflowY:'auto',backgroundColor:'#333',color:'#fff'} }}
      >
        <DialogActions sx={{ justifyContent:'flex-end', padding:'8px' }}>
          <IconButton onClick={handleClosePrivacy} sx={{color:'#fff'}}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ paddingTop:'0px' }}>
          <Typography variant="h6" align="center" gutterBottom>
            Privacy & Security
          </Typography>
          <Tabs
            value={privacyTab}
            onChange={handlePrivacyTabChange}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            sx={{
              display:'flex',
              justifyContent:'center',
              '& .MuiTabs-flexContainer':{ borderBottom:`1px solid ${theme.palette.divider}`, justifyContent:'center' },
            }}
          >
            {privacySections.map((section, index) => (
              <Tab label={section.title} key={index} sx={{color:'#fff'}}/>
            ))}
          </Tabs>
          <Box sx={{ mt:2, textAlign:'center' }}>
            <Typography variant="body1" sx={{color:'#ccc'}}>
              {privacySections[privacyTab].content}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUpgradeDialog}
        onClose={handleCloseUpgrade}
        fullScreen
        sx={{ '& .MuiDialog-paper': { overflow:'hidden',backgroundColor:'#333',color:'#fff'} }}
      >
        <DialogActions sx={{ justifyContent:'flex-end', padding:'8px' }}>
          <IconButton onClick={handleCloseUpgrade} sx={{color:'#fff'}}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ textAlign:'center', paddingTop:'0px', overflowY:'hidden' }}>
          <Typography variant="h6" gutterBottom>
            Upgrade
          </Typography>
          <ToggleButtonGroup
            value={pricingType}
            exclusive
            onChange={handlePricingTypeChange}
            sx={{ mb:2 }}
          >
            <ToggleButton value="month" sx={{color:'#fff'}}>Month</ToggleButton>
            <ToggleButton value="annual" sx={{color:'#fff'}}>Annual</ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ display:'flex', justifyContent:'center', flexWrap:'wrap' }}>
            {plans.map((plan) => (
              <Paper
                key={plan.name}
                onClick={() => setSelectedPlan(plan.name)}
                sx={{
                  p:2, m:1, width:'35%', minWidth:'200px', textAlign:'center', borderRadius:'15px', cursor:'pointer', position:'relative',
                  boxShadow: selectedPlan===plan.name?`0 0 10px ${theme.palette.primary.main}`:theme.shadows[1],
                  border:selectedPlan===plan.name?`2px solid ${theme.palette.primary.main}`:'2px solid transparent',
                  backgroundColor:'#222',color:'#fff'
                }}
              >
                {selectedPlan===plan.name && (
                  <CheckCircleIcon
                    color="primary"
                    sx={{position:'absolute', top:8, right:8}}
                  />
                )}
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                  <Typography variant="h6" sx={{ml:1, color:theme.palette.primary.main}}>
                    {plan.name}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{mt:1,color:'#fff'}}>
                  {plan[pricingType]} / {pricingType}
                </Typography>
                <ul style={{listStyleType:'disc', textAlign:'left'}}>
                  {plan.perks.map((perk) => (
                    <li key={perk}>
                      <Typography variant="body2" sx={{color:'#ccc'}}>{perk}</Typography>
                    </li>
                  ))}
                </ul>
                <Button variant="contained" color="primary" onClick={() => handleSubscribe(plan)} sx={{borderRadius:'20px'}}>
                  Subscribe
                </Button>
              </Paper>
            ))}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openCreateDialog}
        onClose={()=>setOpenCreateDialog(false)}
        fullScreen
        sx={{ '& .MuiDialog-paper': { overflow:'hidden',backgroundColor:'#222',color:'#fff',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start'} }}
      >
        <DialogActions sx={{ justifyContent:'flex-end',width:'100%' }}>
          <IconButton onClick={()=>setOpenCreateDialog(false)} sx={{color:'#fff'}}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{px:2,pb:2,width:'100%',maxWidth:'400px',mx:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start'}}>
          <Box
            component="form"
            onSubmit={handleSubmitCreate}
            sx={{
              width:'100%',
              display:'flex',
              flexDirection:'column',
              alignItems:'center',
              p:2,
              gap:2
            }}
            noValidate
          >
            <TextField
              value={title}
              onChange={(e)=> { if(e.target.value.length<=100)setTitle(e.target.value); }}
              fullWidth
              placeholder="Event Title"
              variant="outlined"
              sx={{'& .MuiOutlinedInput-root':{borderRadius:'20px',backgroundColor:'#333',color:'#fff'}}}
            />
            <Autocomplete
              multiple
              options={allLocations.filter((loc) => loc.toLowerCase().startsWith(inputLocation.toLowerCase()))}
              getOptionLabel={(option)=>option}
              onChange={(event, newValue) => {
                if (newValue.length<=10) {
                  setLocationsArr(newValue);
                  setErrorC(null);
                } else {
                  setErrorC('You can select up to 10 locations.');
                }
              }}
              inputValue={inputLocation}
              onInputChange={(event,newInputValue)=> setInputLocation(newInputValue)}
              renderTags={(value, getTagProps)=>
                value.map((option, index)=>(
                  <Chip label={option} {...getTagProps({index})} key={`${option}-${index}`} sx={{m:'4px',color:'#fff',backgroundColor:'#444'}}/>
                ))
              }
              renderInput={(params)=>
                <TextField {...params}
                  placeholder="Locations"
                  variant="outlined"
                  inputProps={{...params.inputProps,autoComplete:'off',style:{textAlign:'center',color:'#fff'}}}
                  sx={{'& .MuiOutlinedInput-root':{borderRadius:'20px',backgroundColor:'#333'}}}
                />
              }
              filterOptions={(options, state)=>{
                const filtered=customFilterOptions(options,state);
                return isMobile?filtered.slice(0,1):filtered.slice(0,2);
              }}
              sx={{width:'100%'}}
              disableClearable
              noOptionsText=""
              open={openAutocomplete}
            />
            <Box sx={{display:'flex',gap:2,width:'100%'}}>
              <TextField
                type="date"
                value={dateC}
                onChange={(e)=>setDateC(e.target.value)}
                fullWidth
                InputLabelProps={{shrink:true}}
                inputProps={{min:new Date().toISOString().split('T')[0],max:'2024-12-31',autoComplete:'off',style:{textAlign:'center',color:'#fff'}}}
                variant="outlined"
                sx={{'& .MuiOutlinedInput-root':{borderRadius:'20px',backgroundColor:'#333'}}}
              />
              <TextField
                type="time"
                value={timeC}
                onChange={(e)=>setTimeC(e.target.value)}
                fullWidth
                InputLabelProps={{shrink:true}}
                inputProps={{ step:300, autoComplete:'off',style:{textAlign:'center',color:'#fff'} }}
                variant="outlined"
                sx={{'& .MuiOutlinedInput-root':{borderRadius:'20px',backgroundColor:'#333'}}}
              />
            </Box>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={(event) => {
              const { active, over } = event;
              if (active.id !== over?.id) {
                setImagesArr((items) => {
                  const oldIndex = items.findIndex((item) => item.id === active.id);
                  const newIndex = items.findIndex((item) => item.id === over.id);
                  return arrayMove(items, oldIndex, newIndex);
                });
              }
            }}>
              <SortableContext items={imagesArr.map((image)=>image.id)} strategy={horizontalListSortingStrategy}>
                <Box sx={{display:'flex',gap:2,justifyContent:'center',flexWrap:'wrap',maxWidth:'100%',mb:2}}>
                  {imagesArr.map((image)=>(<SortableImage key={image.id} id={image.id} src={image.preview} onRemove={()=>setImagesArr((prev)=>{
                    const updated = prev.filter((img)=>img.id!==image.id);
                    URL.revokeObjectURL(image.preview);
                    return updated;
                  })} isMobile={isMobile}/>))}
                  {imagesArr.length<6 && (
                    <IconButton
                      component="label"
                      sx={{
                        width:100,height:100,
                        borderRadius:'50%',backgroundColor:'#333',color:'#fff',
                        border:'2px dashed #444',display:'flex',alignItems:'center',justifyContent:'center'
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon fontSize="large"/>
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        multiple
                        onChange={(e) => {
                          const files = Array.from(e.target.files);
                          if (imagesArr.length + files.length > 6) {
                            setErrorC('Upload up to 6 images!');
                            return;
                          }
                          const newImages = files.map((file, index) => ({
                            file,
                            preview: URL.createObjectURL(file),
                            id: `image-${Date.now()}-${index}`
                          }));
                          setImagesArr((prev) => [...prev, ...newImages]);
                          setErrorC(null);
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </SortableContext>
            </DndContext>
            {errorC && (
              <Alert severity="error" sx={{borderRadius:'50px',padding:'10px 20px',maxWidth:'fit-content',mx:'auto'}}>
                {errorC}
              </Alert>
            )}
            {successC && (
              <Alert severity="success" sx={{borderRadius:'50px',padding:'10px 20px',maxWidth:'fit-content',mx:'auto'}}>
                {successC}
              </Alert>
            )}
            <Button
              variant="contained"
              type="submit"
              aria-label="create event"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color:'#fff',
                borderRadius:'20px',
                fontSize:'1.5rem',
                textTransform:'none',
                width:'180px',height:50,
                '&:hover': { backgroundColor: theme.palette.primary.dark },
              }}
              disabled={!isFormComplete || loadingC}
            >
              {loadingC?<CircularProgress size={24} color="inherit"/>:'Create'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={eduOpen}
        onClose={()=>setEduOpen(false)}
        fullScreen
        sx={{ '& .MuiDialog-paper': { overflow:'hidden',backgroundColor:'#333',color:'#fff'} }}
      >
        <DialogActions sx={{ justifyContent:'flex-end', padding:'8px' }}>
          <IconButton onClick={()=>setEduOpen(false)} sx={{color:'#fff'}}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ display:'flex', flexDirection:'column', height:'100%', overflow:'hidden',pt:2 }}>
          <Tabs value={eduTab} onChange={(e,v)=>setEduTab(v)} variant="scrollable" scrollButtons="auto" sx={{color:'#fff',alignSelf:'center'}}>
            <Tab label="Assignments" sx={{color:'#fff'}}/>
            <Tab label="Quizzes" sx={{color:'#fff'}}/>
            <Tab label="Scores" sx={{color:'#fff'}}/>
          </Tabs>
          <Box sx={{ display:'flex', gap:2, mb:2,mt:2,alignSelf:'center',width:'300px' }}>
            <TextField
              placeholder=""
              variant="outlined"
              value={searchInput}
              onChange={(e)=>setSearchInput(e.target.value)}
              sx={{
                width:'100%',
                '& .MuiOutlinedInput-root':{borderRadius:'25px',backgroundColor:'#444',color:'#fff'}
              }}
              InputProps={{
                startAdornment:(
                  <InputAdornment position="start">
                    <SearchIcon sx={{color:'#fff'}}/>
                  </InputAdornment>
                ),
                endAdornment:(
                  <>
                    {searchInput && (
                      <InputAdornment position="end">
                        <IconButton onClick={()=>setSearchInput('')} aria-label="Clear search">
                          <Close sx={{color:'#fff'}}/>
                        </IconButton>
                      </InputAdornment>
                    )}
                    <InputAdornment position="end">
                      <IconButton onClick={handleVoiceSearchEDU} aria-label="Record voice">
                        <Mic sx={{color:'#fff'}}/>
                      </IconButton>
                    </InputAdornment>
                  </>
                )
              }}
            />
          </Box>
          <Box sx={{ flexGrow:1, overflowY:'auto', p:2,
            '&::-webkit-scrollbar': {
              width:'8px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius:'4px'
            },color:'#fff',textAlign:'center' }}>
            {eduTab===0 && (
              <>
                <Typography sx={{color:'#ccc',mb:2}}>Sample Assignments: Assignment 1, Assignment 2, etc.</Typography>
                {selectedClassDetail ? (
                  <>No detailed class needed for assignments</>
                ) : (
                  disciplines.map((discipline) => {
                    const disciplineClasses = filteredClasses.filter((cls)=> cls.discipline===discipline);
                    if(disciplineClasses.length>0){
                      return (
                        <Box key={discipline} sx={{width:'100%', mb:4}}>
                          <Typography variant="h5" sx={{textAlign:'left',mb:1}}>{discipline}</Typography>
                          <Grid container spacing={3}>
                            {disciplineClasses.map((cls)=>(
                              <Grid item xs={12} sm={6} md={3} key={cls.id}>
                                <Card
                                  sx={{
                                    height:'150px',
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'space-between',
                                    p:2,
                                    backgroundColor:'#444',
                                    color:'#fff'
                                  }}
                                >
                                  <Typography variant="h6" sx={{mb:1,fontWeight:'bold'}}>
                                    {cls.id} - {cls.name}
                                  </Typography>
                                  <Typography sx={{color:'#ccc'}}>
                                    {cls.discipline}
                                  </Typography>
                                  <Typography variant="body2" color="#aaa">
                                    Credits: {cls.credits}
                                  </Typography>
                                  <Button variant="contained" sx={{borderRadius:'20px',mt:1}}>Select</Button>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      );
                    }
                    return null;
                  })
                )}
              </>
            )}
            {eduTab===1 && (
              <>
                <Typography sx={{color:'#ccc',mb:2}}>Sample Quizzes: Quiz 1 (80%), Quiz 2 (90%)</Typography>
                <Typography>Quiz 1: 20/25 points</Typography>
                <Typography>Quiz 2: 18/20 points</Typography>
              </>
            )}
            {eduTab===2 && (
              <>
                <Typography sx={{color:'#ccc',mb:2}}>Sample Scores</Typography>
                <Typography>Assignment 1: 90% (Submit?)</Typography>
                <Typography>Assignment 2: 85% (Submit?)</Typography>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Explore;